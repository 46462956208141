<template>
  <router-view></router-view>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseProfileListing",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
